import * as blobUtil from 'blob-util';

export const IMAGE_TYPE = {
  PNG: 'image/png',
  JPEG: 'image/jpeg',
  JPG: 'image/jpg',
};

export default function checkImageType(type) {
  return [IMAGE_TYPE.JPEG, IMAGE_TYPE.JPG, IMAGE_TYPE.PNG].some((i) => i === type);
}

// get the image type for the base64 image
function returnImageType(image) {
  let imageType = image.src.split(';')[0].split(':')[1];

  if (!checkImageType(imageType)) {
    imageType = IMAGE_TYPE.JPEG;
  }
  return imageType;
}

const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

// function getRadianAngle(degreeValue: any) {
//   return (degreeValue * Math.PI) / 180;
// }

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} imageSrc - Image File url
 * @param {Object} pixelCrop - pixelCrop
 * @param {Boolean} compress - If true then compresses the image by 0.8 and into JPEG
 * Object provided by react-easy-crop
 */
export async function getCroppedImg(imageSrc, pixelCrop, compress = false) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const safeArea = compress
    ? Math.max(image.width, image.height)
    : 2 * ((Math.max(image.width, image.height) / 2) * Math.sqrt(2));

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea;
  canvas.height = safeArea;

  // translate canvas context to a central location on image to allow rotating around the center.
  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.translate(-safeArea / 2, -safeArea / 2);

  // draw rotated image and store data.
  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5
  );
  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(
    data,
    Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
    Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
  );

  // Return image based on compression flag
  return Promise.resolve(
    canvas.toDataURL(
      compress ? 'image/jpeg' : returnImageType(image),
      compress ? 0.9 : 0.8
    )
  );
}

export async function resizedataURL(datas, wantedWidth, wantedHeight, imageType) {
  return new Promise((resolve, reject) => {
    // We create an image to receive the Data URI
    const img = document.createElement('img');

    // putting cropped image to img src to calculate cropped image width and height
    // to draw on canvas.
    img.src = datas;

    // When the event "onload" is triggered we can resize the image.
    img.onload = function () {
      // We create a canvas and get its context.
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      // We set the dimensions at the wanted size.
      canvas.width = wantedWidth || img.width; // width of cropped image
      canvas.height = wantedHeight || img.height; // height of cropped image

      // We resize the image with the canvas method drawImage();
      ctx.drawImage(this, 0, 0, img.width, img.height);
      const dataURI = imageType
        ? canvas?.toDataURL(imageType)
        : canvas?.toDataURL('image/jpeg');
      const dataBlob = blobUtil.dataURLToBlob(dataURI);
      resolve(dataBlob);
    };
    // We put the Data URI in the image's src attribute
    img.src = datas;
  });
}

/**
 * function to convert upload image from MIME type to base64 for upload purpose
 * @param datas
 * @param wantedWidth
 * @param wantedHeight
 * @returns base64 image format
 */
export async function resizeImageIntoDataUrl(datas, wantedWidth, wantedHeight) {
  return new Promise((resolve, reject) => {
    // We create an image to receive the Data URI
    const img = document.createElement('img');

    // putting cropped image to img src to calculate cropped image width and height
    // to draw on canvas.
    img.src = datas;

    // When the event "onload" is triggered we can resize the image.
    img.onload = async function () {
      // We create a canvas and get its context.
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      // We set the dimensions at the wanted size.
      canvas.width = wantedWidth; // width of cropped image
      canvas.height = wantedHeight; // height of cropped image

      // We resize the image with the canvas method drawImage();
      await ctx.drawImage(this, 0, 0, wantedWidth, wantedHeight);

      const dataURI = canvas?.toDataURL(returnImageType(img));
      resolve(dataURI);
    };
    // We put the Data URI in the image's src attribute
    img.src = datas;
  });
}
