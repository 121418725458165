import { Button } from '@/components/DesignSystem/AtomicComponents';
import getCustomCtaText from '@/utils/getCustomCtaText';

export const RenderProductCTA = ({
  storeInfo,
  checkIsCtaActive,
  handleAddToCart,
  onBuyNowClick,
  source,
}) => {
  const isCartPage = () => {
    if (typeof window !== 'undefined') {
      const pathname = window.location.pathname;
      return pathname.includes('/cart');
    }
    return false;
  };

  return (
    <div className="pp-action-cta tw-mt-[16px] tw-flex tw-justify-center tw-gap-[16px] md:tw-gap-[24px]">
      <Button
        label="Add to Cart"
        variant={isCartPage() ? '' : 'outline'}
        borderColor={storeInfo?.theme?.colors?.primary_color}
        backgroundColor={storeInfo?.theme?.colors?.primary_color}
        fontSize="14"
        fontWeight={500}
        px="12px"
        py="12px"
        width="100%"
        roundness={storeInfo?.theme?.roundness || '4px'}
        className={`${checkIsCtaActive() ? 'tw-cursor-pointer tw-opacity-100' : 'tw-pointer-events-none tw-opacity-50'}`}
        onClick={() => handleAddToCart('add')}
      />
      {!isCartPage() && (
        <Button
          borderColor="#EDEDED"
          borderWidth="0px"
          label={storeInfo?.theme?.cta_config?.pdp_cta || getCustomCtaText('buy_now_cta')}
          backgroundColor={storeInfo?.theme?.colors?.primary_color}
          fontSize="14"
          fontWeight={500}
          px="12px"
          py="12px"
          width="100%"
          roundness={storeInfo?.theme?.roundness || '4px'}
          className={`${checkIsCtaActive() ? 'tw-cursor-pointer tw-opacity-100' : 'tw-pointer-events-none tw-opacity-50'}`}
          onClick={onBuyNowClick}
        />
      )}
    </div>
  );
};
