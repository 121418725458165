import { PlusIcon, RemoveIcon } from '../utils/SvgExport';

const ImageUpload = (props) => {
  const { tempImages, handleImageUpload, handleImageRemove, allImageMandatory } = props;
  return (
    <div className="pp-image-upload tw-mt-4 tw-flex tw-flex-col tw-gap-[0.75rem]">
      <div className="tw-flex tw-gap-[0.5rem]">
        <span className="tw-text-[0.875rem] tw-font-medium tw-text-[#222] md:tw-text-[1rem]">
          Images
          {!!allImageMandatory && <span className="tw-ml-[2px] tw-text-[#222]">*</span>}
        </span>
        <span className="tw-text-[0.875rem] tw-font-normal tw-text-[#999] md:tw-text-[1rem]">
          (JPG, PNG up to 5 MB)
        </span>
      </div>
      <div className={`tw-relative tw-grid tw-grid-cols-4 tw-gap-3 md:tw-grid-cols-5`}>
        {tempImages?.map((image, index) => (
          <div key={index} className="tw-relative">
            {image ? (
              <div className="tw-relative tw-h-[70px] tw-w-full md:tw-h-[100px] md:tw-w-[100px]">
                <img
                  src={image}
                  alt={`Upload ${index}`}
                  className="tw-h-full tw-w-full tw-border tw-border-solid tw-border-[#0000001A] tw-object-cover"
                />
                <span
                  className="tw-absolute tw-right-[0.25rem] tw-top-[0.25rem] tw-flex tw-cursor-pointer tw-items-center tw-justify-center tw-rounded tw-bg-[#000000A6]"
                  onClick={() => handleImageRemove(index)}
                >
                  <RemoveIcon />
                </span>
              </div>
            ) : (
              <div
                className="tw-flex tw-h-[70px] tw-w-full tw-cursor-pointer tw-items-center tw-justify-center tw-rounded tw-border tw-border-solid tw-border-[#E9E9E9] tw-bg-[#F7F7F7] md:tw-h-[100px] md:tw-w-[100px]"
                key={index}
                onClick={() => document.getElementById(`upload-${index}`).click()}
              >
                <span>
                  <PlusIcon color="#0E0E0E" />
                </span>
                <input
                  type="file"
                  id={`upload-${index}`}
                  className="tw-hidden"
                  accept="image/*"
                  onChange={(e) => handleImageUpload(e, index)}
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageUpload;
