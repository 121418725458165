export const PlusIcon = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <g clip-path="url(#clip0_115_1132)">
      <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" fill={color || '#2F80ED'} />
    </g>
    <defs>
      <clipPath id="clip0_115_1132">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const CancelIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <g clip-path="url(#clip0_115_1154)">
      <path
        d="M14.59 8L12 10.59L9.41 8L8 9.41L10.59 12L8 14.59L9.41 16L12 13.41L14.59 16L16 14.59L13.41 12L16 9.41L14.59 8ZM12 2C6.47 2 2 6.47 2 12C2 17.53 6.47 22 12 22C17.53 22 22 17.53 22 12C22 6.47 17.53 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
        fill="#0E0E0E"
      />
    </g>
    <defs>
      <clipPath id="clip0_115_1154">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const ErrorIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <g clip-path="url(#clip0_117_1656)">
      <path
        d="M9.16675 12.4998H10.8334V14.1665H9.16675V12.4998ZM9.16675 5.83317H10.8334V10.8332H9.16675V5.83317ZM9.99175 1.6665C5.39175 1.6665 1.66675 5.39984 1.66675 9.99984C1.66675 14.5998 5.39175 18.3332 9.99175 18.3332C14.6001 18.3332 18.3334 14.5998 18.3334 9.99984C18.3334 5.39984 14.6001 1.6665 9.99175 1.6665ZM10.0001 16.6665C6.31675 16.6665 3.33341 13.6832 3.33341 9.99984C3.33341 6.3165 6.31675 3.33317 10.0001 3.33317C13.6834 3.33317 16.6667 6.3165 16.6667 9.99984C16.6667 13.6832 13.6834 16.6665 10.0001 16.6665Z"
        fill="#9A0C00"
      />
    </g>
    <defs>
      <clipPath id="clip0_117_1656">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const RemoveIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M4.26667 12.6668L3.33333 11.7335L7.06667 8.00016L3.33333 4.26683L4.26667 3.3335L8 7.06683L11.7333 3.3335L12.6667 4.26683L8.93333 8.00016L12.6667 11.7335L11.7333 12.6668L8 8.9335L4.26667 12.6668Z"
      fill="#E8EAED"
    />
  </svg>
);

export const CartPlusIcon = ({ color = '#0E0E0E' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
  >
    <g clip-path="url(#clip0_286_2302)">
      <path
        d="M12.6666 9.16683H8.66659V13.1668H7.33325V9.16683H3.33325V7.8335H7.33325V3.8335H8.66659V7.8335H12.6666V9.16683Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_286_2302">
        <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export const CartMinusIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
  >
    <path d="M3.33325 9.16683V7.8335H12.6666V9.16683H3.33325Z" fill="#0E0E0E" />
  </svg>
);

export const RightArrowIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
  >
    <g clip-path="url(#clip0_286_2296)">
      <path
        d="M7.15845 13.9925L10.9751 10.1675L7.15845 6.34248L8.33345 5.16748L13.3334 10.1675L8.33345 15.1675L7.15845 13.9925Z"
        fill="#6B6B6B"
      />
    </g>
    <defs>
      <clipPath id="clip0_286_2296">
        <rect width="20" height="20" fill="white" transform="translate(0 0.16748)" />
      </clipPath>
    </defs>
  </svg>
);
