const TextBox = (props) => {
  const { textCount, textData, handleTextDataChange } = props;
  const currentTextLength = textData?.length || 0;
  return (
    <div className="pp-text-box tw-flex tw-flex-col tw-gap-[0.75rem]">
      <div className="tw-flex tw-gap-[0.5rem]">
        <span className="tw-text-[0.875rem] tw-font-medium tw-text-[#222] md:tw-text-[1rem]">
          Text
        </span>
      </div>
      <div className="tw-flex tw-flex-col tw-gap-[6px]">
        <textarea
          placeholder="Enter custom text"
          className="personalization__input--textbox tw-h-[105px] tw-resize-none tw-rounded-[4px] tw-border-[#D2D2D2] tw-px-[0.75rem] tw-py-[0.625rem] tw-text-[0.875rem] tw-font-medium tw-leading-5 tw-text-[#0E0E0E] md:tw-text-[1rem]"
          value={textData || ''}
          onChange={handleTextDataChange}
        />
        <span className="tw-flex tw-justify-end tw-text-[0.75rem] tw-font-normal tw-text-[#6B6B6B] md:tw-text-[0.875rem]">
          {currentTextLength}/{textCount}
        </span>
      </div>
    </div>
  );
};

export default TextBox;
