import React, { useState } from 'react';
import { READ_MORE_TEXT_LIMIT } from '../utils/constant';

const ReadMoreReadLess = ({ text, color, maxChar }) => {
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => setIsReadMore(!isReadMore);

  const isLongText = text?.length > (maxChar ?? READ_MORE_TEXT_LIMIT);
  const displayedText =
    isReadMore && isLongText
      ? `${text?.substring(0, maxChar || READ_MORE_TEXT_LIMIT)}...`
      : text;
  const toggleText = isReadMore ? 'Read More' : 'Read Less';

  return (
    <span
      className={`tw-break-all tw-text-[0.75rem] tw-font-normal tw-leading-4 md:tw-text-[0.875rem] tw-text-[${color}] ${maxChar ? 'md:tw-text-[12px]' : 'md:tw-text-[0.875rem]'}`}
    >
      {displayedText}
      {isLongText && (
        <span
          onClick={toggleReadMore}
          className={`tw-cursor-pointer tw-text-[0.75rem] tw-font-medium tw-text-[#0E0E0E] tw-underline md:tw-text-[0.875rem] ${isReadMore ? '' : 'tw-pl-[6px]'}`}
        >
          {toggleText}
        </span>
      )}
    </span>
  );
};

export default ReadMoreReadLess;
